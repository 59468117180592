import {useTranslation} from 'react-i18next';
import {removeFromLocalStorage} from 'whatcrm-core';
import _ from 'lodash';
import axios, {AxiosResponse} from 'axios';

import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

import {getFilterParams} from './actions';

interface RequestParams {
  [key: string]: number | string | string[] | undefined;
}

interface RequestArgs {
  data?: object;
  isErrorReturn?: boolean;
  method?: AppEnv.RequestMethod;
  params?: RequestParams;
  timeout?: number;
}

const columns = {
  cloudpayments: 'clouds',
  stripe: 'stripes',
  tinkoff: 'tinkoffs'
};

const pagination = [
  'x-pagination-current-page',
  'x-pagination-page-count',
  'x-pagination-per-page',
  'x-pagination-total-count'
];

const useRequest = () => {
  const {client, pushNotification, setClient} = useAppContext();
  const {t} = useTranslation();

  const throwErr = (error_code?: string, error_text?: string) => {
    pushNotification(
      `${t`Error`}${error_code ? ` ${error_code}` : ''}`,
      error_text || t`Something went wrong.`
    );

    return {headers: null, data: null};
  };

  const handleThen = (res: AxiosResponse) => {
    const {data, headers} = res;
    if (data == null) return throwErr();

    const isError =
      typeof data == 'object' &&
      ('error' in data || 'error_code' in data || 'error_text' in data);

    if (isError) return throwErr(data.error_code, data.error_text);

    return {data, headers: _.pick(headers, pagination)};
  };

  // eslint-disable-next-line
  const handleCatch = (e: any, isErrorReturn: boolean) => {
    const {code, response} = e;

    if (code == 'ERR_CANCELED') {
      return {headers: null, data: null};
    } else if (response?.status == 401) {
      removeFromLocalStorage('client');
      setClient(null);
      return {headers: null, data: null};
    } else if (isErrorReturn) {
      return {
        headers: null,
        data: response?.data || null
      };
    }

    const {data} = response || {};
    const {['0']: res} = data || {};

    const status = response?.data?.error_code || response?.status || '';

    const message =
      response?.data?.error_text || res?.message || t`Something went wrong.`;

    pushNotification(`${t`Error`}${status ? ` ${status}` : ''}`, message);
    return {headers: null, data: null};
  };

  const request = async <T>(
    path: string,
    {params, method = 'get', data, timeout, isErrorReturn}: RequestArgs = {}
  ): Promise<AppEnv.Response<T>> => {
    const controller = new AbortController();
    const isAuthorization = path == 'authorization';

    const headers = isAuthorization
      ? {'X-Lk-Token': process.env.REACT_APP_KEY}
      : {Authorization: `Bearer ${client?.access_token}`};

    const instance = axios.create({
      baseURL: process.env.REACT_APP_API,
      headers,
      signal: controller.signal
    });

    if (timeout) setTimeout(() => controller.abort(), timeout);

    return instance(path, {data, method, params})
      .then(handleThen)
      .catch(res => handleCatch(res, !!isErrorReturn));
  };

  // const deleteAutomations = (id: number) =>
  //   request(`automations/${id}`, {method: 'delete'});

  const deleteBillings = (id: number) =>
    request<string>(`billings/${id}`, {method: 'delete'});

  const deleteBlacklists = (id: number) =>
    request<string>(`blacklists/${id}`, {method: 'delete'});

  const deleteCrmTypes = (id: number) =>
    request<string>(`crm_types/${id}`, {method: 'delete'});

  const deleteInstances = (id: number) =>
    request<string>(`instances/${id}`, {method: 'delete'});

  const deleteIntegrations = (id: number) =>
    request<string>(`integrations/${id}`, {method: 'delete'});

  const deleteNotifications = (id: number) =>
    request<string>(`notifications/${id}`, {method: 'delete'});

  const deleteOptions = (id: number) =>
    request<string>(`options/${id}`, {method: 'delete'});

  const deleteSubscriptions = (column: AppEnv.PaymentSystem, id: number) =>
    request<AppEnv.Subscription>(`${columns[column]}/${id}`, {
      method: 'delete'
    });

  const deleteTelphins = (id: number) =>
    request<string>(`telphins/${id}`, {method: 'delete'});

  const deleteTokens = (id: number) =>
    request<string>(`tokens/${id}`, {method: 'delete'});

  const deleteUsers = (id: number) =>
    request(`users/${id}`, {isErrorReturn: true, method: 'delete'});

  const fetchEvents = (page: number, filter: AppEnv.EventRequest) =>
    request<AppEnv.Event[]>('events', {
      params: {...getFilterParams(filter), page, sort: '-id'}
    });

  const fetchInstanceRebuilt = (domain: string, integration_type_id: number) =>
    request('instance/rebuilt', {params: {domain, integration_type_id}});

  const fetchInstanceResuscitate = (chat_key: string) =>
    request<AppEnv.Instance>('instance/resuscitate', {
      params: {id: chat_key, expand: 'integration,tariff'}
    });

  interface FetchPayments {
    end?: string;
    id?: number;
    integration_id?: number;
    start?: string;
  }

  const fetchPayments = <T>({
    end,
    id,
    integration_id,
    start
  }: FetchPayments) => {
    const sort = integration_id ? '-date_yslug' : undefined;

    return request<T>(`payments${id ? '/partner' : ''}`, {
      params: {end, id, integration_id, sort, start}
    });
  };

  const freeInstance = (chat_key: string) =>
    request<AppEnv.Instance>('instance/free', {params: {id: chat_key}});

  const fetchAutomations = (page: number, filter?: AppEnv.AutomationRequest) =>
    request<AppEnv.Automation[]>('automations', {
      params: {page, expand: 'integration', ...getFilterParams(filter)}
    });

  const fetchBillings = (page: number, filter: AppEnv.PaymentRequest) =>
    request<AppEnv.Payment[]>('billings', {
      params: {...getFilterParams(filter), page, sort: '-date_yslug'}
    });

  const fetchBlacklists = (page: number, filter?: AppEnv.BlockedRequest) =>
    request<AppEnv.Blocked[]>('blacklists', {
      params: {
        ...getFilterParams(filter),
        expand: 'integration',
        page,
        sort: '-id'
      }
    });

  const fetchCrmTypes = (page: number) =>
    request<AppEnv.Crm[]>('crm_types', {params: {page}});

  interface FetchInstances {
    filter?: AppEnv.InstanceRequest;
    id?: number;
    isExpand?: boolean;
    page?: number;
  }

  const fetchInstances = <T>({
    filter,
    id,
    isExpand,
    page
  }: FetchInstances = {}) => {
    const expand = isExpand ? 'integration,tariff' : undefined;
    const sort = !id ? '-date_update' : undefined;

    return request<T>(`instances${id ? `/${id}` : ''}`, {
      params: {...getFilterParams(filter), expand, page, sort}
    });
  };

  interface StatusState {
    accountStatus: string;
    state: string;
  }

  interface StatusError {
    error: string;
    error_code: string;
    error_text: string;
  }

  const fetchInstanceStatus = (chat_key: string) =>
    request<StatusState | StatusError>('instance', {
      params: {id: chat_key, method: 'status'},
      timeout: 10000
    });

  interface FetchIntegrationsParams {
    'per-page'?: number;
    fields?: string;
  }

  interface FetchIntegrations {
    filter?: AppEnv.IntegrationRequest;
    id?: number;
    page?: number;
    params?: FetchIntegrationsParams;
  }

  const fetchIntegrations = <T>({
    page = 1,
    filter,
    params,
    id
  }: FetchIntegrations) =>
    request<T>(`integrations${id ? `/${id}` : ''}`, {
      params: !id
        ? {page, sort: '-id', ...params, ...getFilterParams(filter)}
        : undefined
    });

  const fetchInvoices = (page: number) =>
    request<AppEnv.Invoice[]>(`invoices`, {params: {page}});

  const fetchManagers = (page: number, filter?: AppEnv.EmployeeRequest) =>
    request<AppEnv.Employee[]>('managers', {
      params: {page, extend: 'integration', ...getFilterParams(filter)}
    });

  const fetchNotifications = (
    page: number,
    filter?: AppEnv.NotificationRequest
  ) =>
    request<AppEnv.Notification[]>('notifications', {
      params: {...getFilterParams(filter), expand: 'type', page}
    });

  const fetchOptions = (page: number) =>
    request<AppEnv.SystemOption[]>('options', {params: {page}});

  interface FetchPartners {
    filter?: AppEnv.PartnerRequest;
    id?: number;
    isClients?: boolean;
    page?: number;
  }

  const fetchPartners = <T>({filter, id, isClients, page}: FetchPartners) => {
    const expand = id && !isClients ? 'integrations' : undefined;

    return request<T>(
      `partners${id ? `/${id}${isClients ? '/clients' : ''}` : ''}`,
      {params: {...getFilterParams(filter), expand, page, sort: '-date_add'}}
    );
  };

  interface FetchPartnersDisbursements {
    success: boolean;
  }

  const fetchPartnersDisbursements = () =>
    request<FetchPartnersDisbursements>('partners/disbursements');

  const fetchPromoCodes = (page: number, filter?: AppEnv.PromoCodeRequest) =>
    request<AppEnv.PromoCode[]>('promo_codes', {
      params: {...getFilterParams(filter), expand: 'integration', page}
    });

  const fetchServers = (version: string | undefined) =>
    request<AppEnv.Server[]>('servers', {params: {version}});

  interface FetchStatisticsParams {
    currency?: string;
    end?: string;
    integration_type_id?: string;
    is_deleted?: 1;
    report?: string;
    start?: string;
    tariff_id?: string[];
    version?: string;
  }

  const fetchStatistics = <T>(
    category: AppEnv.StatsCategory,
    params?: FetchStatisticsParams
  ) =>
    request<T>(`statistics/${category}`, {
      params: {...params, sort: '-id', type: 'AVITO - AVITO'}
    });

  interface FetchSubscriptions {
    filter?: AppEnv.SubscriptionRequest;
    id?: number;
    page?: number;
  }

  const fetchSubscriptions = <T>(
    column: AppEnv.PaymentSystem,
    {page, id, filter}: FetchSubscriptions
  ) => {
    const expand = page ? 'integration,tariff' : 'subscription';
    const sort = page ? '-id' : undefined;

    return request<T>(`${columns[column]}${id ? `/${id}` : ''}`, {
      params: {...getFilterParams(filter), expand, page, sort}
    });
  };

  const fetchTariffs = (page: number, filter?: AppEnv.RateRequest) =>
    request<AppEnv.Rate[]>('tariffs', {
      params: {expand: 'type', page, ...getFilterParams(filter)}
    });

  interface FetchTelphins {
    filter?: AppEnv.TelphinRequest;
    id?: number;
    page?: number;
  }

  const fetchTelphins = <T>({filter, id, page}: FetchTelphins) => {
    const expand = id ? 'subscription' : undefined;
    const sort = id ? undefined : '-id';

    return request<T>(`telphins${id ? `/${id}` : ''}`, {
      params: {...getFilterParams(filter), expand, page, sort}
    });
  };

  const fetchTokens = (page: number) =>
    request<AppEnv.Token[]>('tokens', {params: {page}});

  const fetchUsers = (page: number) =>
    request<AppEnv.User[]>('users', {params: {page}});

  interface PostAuthorization {
    access_token?: string;
    role: AppEnv.Role;
    success?: number;
  }

  const postAuthorization = (username: string, password: string) =>
    request<PostAuthorization>('authorization', {
      method: 'post',
      data: {LoginForm: {username, password}}
    });

  const postBillings = (data: AppEnv.PaymentForm) =>
    request<AppEnv.Payment>('billings', {data, method: 'post'});

  const postCrmTypes = (data: AppEnv.CrmForm) =>
    request<AppEnv.Crm>('crm_types', {method: 'post', data});

  interface Errors {
    [index: string]: string[];
  }

  interface PostInstanceChange {
    errors: Errors;
    message: string;
    success: 0 | 1;
  }

  const postInstanceChange = (
    old_type_crm_id: number,
    old_domain: string,
    new_type_crm_id: number,
    new_domain: string
  ) =>
    request<PostInstanceChange>('instance/change', {
      method: 'post',
      data: {new_domain, new_type_crm_id, old_domain, old_type_crm_id}
    });

  const postIntegrations = (data: AppEnv.IntegrationForm) =>
    request<AppEnv.Integration>('integrations', {method: 'post', data});

  const postManagers = (data: AppEnv.EmployeeForm) =>
    request<AppEnv.Employee>('managers', {data, method: 'post'});

  const postNotifications = (data: AppEnv.NotificationForm) =>
    request<AppEnv.Notification>('notifications', {method: 'post', data});

  const postOptions = (data: AppEnv.SystemOptionForm) =>
    request<AppEnv.SystemOption>('options', {method: 'post', data});

  const postPartners = (data: AppEnv.PartnerForm) =>
    request<AppEnv.Partner>('partners', {method: 'post', data});

  interface PostPaymentsTelphin {
    count: number;
  }

  const postPaymentsTelphin = (file: File) => {
    const data = new FormData();
    data.append('file', file);

    return request<PostPaymentsTelphin>('payments/telphin', {
      data,
      method: 'post'
    });
  };

  const postPromoCodes = (data: AppEnv.PromoCodeForm) =>
    request<AppEnv.PromoCode>('promo_codes', {method: 'post', data});

  const postTariffs = (data: AppEnv.RateForm) =>
    request<AppEnv.Rate>('tariffs', {method: 'post', data});

  interface PostTelphins {
    id: number;
  }

  const postTelphins = (data: AppEnv.TelphinForm) =>
    request<PostTelphins>('telphins', {method: 'post', data});

  const postTokens = (data: AppEnv.TokenForm) =>
    request<AppEnv.Token>('tokens', {method: 'post', data});

  const postUsers = (data: AppEnv.UserForm) =>
    request<AppEnv.User>('users', {method: 'post', data});

  const putAutomations = (id: number, data: AppEnv.AutomationForm) =>
    request<AppEnv.Automation>(`automations/${id}`, {
      data,
      method: 'put',
      params: {expand: 'integration'}
    });

  const putBillings = (id: number, data: AppEnv.PaymentForm, amount?: string) =>
    request<AppEnv.Payment>(`billings/${id}`, {
      data,
      method: 'put',
      params: {amount}
    });

  const putBlacklists = (id: number, data: AppEnv.BlockedForm) =>
    request<AppEnv.Blocked>(`blacklists/${id}`, {data, method: 'put'});

  const putCrmTypes = (id: number, data: AppEnv.CrmForm) =>
    request<AppEnv.Crm>(`crm_types/${id}`, {method: 'put', data});

  const putInstances = (id: number, data: AppEnv.InstanceForm) =>
    request<AppEnv.Instance>(`instances/${id}`, {
      params: {expand: 'integration,tariff'},
      method: 'put',
      data
    });

  const putIntegrations = (id: number, data: AppEnv.IntegrationForm) =>
    request<AppEnv.Integration>(`integrations/${id}`, {data, method: 'put'});

  const putInvoices = (id: number, data: AppEnv.InvoiceForm) =>
    request<AppEnv.Invoice>(`invoices/${id}`, {
      data: {...data, date_update: Math.floor(+new Date() / 1000)},
      method: 'put'
    });

  const putManagers = (id: number, data: AppEnv.EmployeeForm) =>
    request<AppEnv.Employee>(`managers/${id}`, {method: 'put', data});

  const putNotifications = (id: number, data: AppEnv.NotificationForm) =>
    request<AppEnv.Notification>(`notifications/${id}`, {method: 'put', data});

  const putOptions = (id: number, data: AppEnv.SystemOptionForm) =>
    request<AppEnv.SystemOption>(`options/${id}`, {method: 'put', data});

  const putPartners = (id: number, data: AppEnv.PartnerForm) =>
    request<AppEnv.Partner>(`partners/${id}`, {data, method: 'put'});

  interface PromoCodeIsDeleted {
    is_deleted: 0 | 1;
  }

  const putPromoCodes = (
    id: number,
    data: AppEnv.PromoCodeForm | PromoCodeIsDeleted
  ) => request<AppEnv.PromoCode>(`promo_codes/${id}`, {data, method: 'put'});

  interface PutServerPriority {
    count: number;
    priority: number;
  }

  const putServerPriority = (IpV6: string, priority: number) =>
    request<PutServerPriority>('servers/priority', {
      method: 'put',
      params: {IpV6, priority}
    });

  const putSubscriptions = (
    column: AppEnv.PaymentSystem,
    id: number,
    data: AppEnv.SubscriptionForm
  ) =>
    request<AppEnv.SubscriptionExpand>(`${columns[column]}/${id}`, {
      data,
      method: 'put',
      params: {expand: 'integration,tariff'}
    });

  const putTariffs = (id: number, data: AppEnv.RateForm) =>
    request<AppEnv.Rate>(`tariffs/${id}`, {
      data,
      method: 'put',
      params: {expand: 'type'}
    });

  const putTelphins = (id: number, data: AppEnv.TelphinForm) =>
    request<AppEnv.Telphin>(`telphins/${id}`, {data, method: 'put'});

  const putTokens = (id: number, data: AppEnv.TokenForm) =>
    request<AppEnv.Token>(`tokens/${id}`, {method: 'put', data});

  const putUsers = (id: number, data: AppEnv.UserForm) =>
    request<AppEnv.User>(`users/${id}`, {data, method: 'put'});

  const requestInstance = (
    method: AppEnv.RequestMethod,
    chat_key: string,
    name: string,
    params: RequestParams
  ) =>
    request('instance', {
      method,
      params: {id: chat_key, method: name, ...params},
      isErrorReturn: true
    });

  type InstanceMethod =
    | 'close'
    | 'destroy'
    | 'logout'
    | 'reboot'
    | 'screenshot'
    | 'status'
    | 'WWebVersion';

  const updateInstance = <T>(id: string, method: InstanceMethod, full?: 1) =>
    request<T>('instance', {params: {full, id, method}});

  return {
    // deleteAutomationsReq,
    deleteBillings,
    deleteBlacklists,
    deleteCrmTypes,
    deleteInstances,
    deleteIntegrations,
    deleteNotifications,
    deleteOptions,
    deleteSubscriptions,
    deleteTelphins,
    deleteTokens,
    deleteUsers,
    fetchAutomations,
    fetchBillings,
    fetchBlacklists,
    fetchCrmTypes,
    fetchEvents,
    fetchInstanceRebuilt,
    fetchInstanceResuscitate,
    fetchInstances,
    fetchInstanceStatus,
    fetchIntegrations,
    fetchInvoices,
    fetchManagers,
    fetchNotifications,
    fetchOptions,
    fetchPartners,
    fetchPartnersDisbursements,
    fetchPayments,
    fetchPromoCodes,
    fetchServers,
    fetchStatistics,
    fetchSubscriptions,
    fetchTariffs,
    fetchTelphins,
    fetchTokens,
    fetchUsers,
    freeInstance,
    postAuthorization,
    postBillings,
    postCrmTypes,
    postInstanceChange,
    postIntegrations,
    postManagers,
    postNotifications,
    postOptions,
    postPartners,
    postPaymentsTelphin,
    postPromoCodes,
    postTariffs,
    postTelphins,
    postTokens,
    postUsers,
    putAutomations,
    putBillings,
    putBlacklists,
    putCrmTypes,
    putInstances,
    putIntegrations,
    putInvoices,
    putManagers,
    putNotifications,
    putOptions,
    putPartners,
    putPromoCodes,
    putServerPriority,
    putSubscriptions,
    putTariffs,
    putTelphins,
    putTokens,
    putUsers,
    requestInstance,
    updateInstance
  };
};

export default useRequest;
