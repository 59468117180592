import React from 'react';
import {MultiSelect} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const options = [
  {label: 'RUB', value: 'RUB'},
  {label: 'USD', value: 'USD'}
];

const Currency = () => {
  const [crmField] = useField<string | undefined>('crm');
  const [field, , helpers] = useField<string | string[] | undefined>(
    'currency'
  );
  const {t} = useTranslation();

  const value = typeof field.value == 'string' ? [field.value] : field.value;

  return (
    <MultiSelect
      {...field}
      isDisabled={!crmField.value}
      onChange={helpers.setValue}
      options={crmField.value ? options : []}
      placeholder={t`Currency`}
      style={{marginBottom: 8}}
      value={value}
    />
  );
};

export default Currency;
