import React from 'react';
import {EmptyBox, Preloader, Title} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {BarGraph, Legend} from 'components';
import * as AppEnv from 'app-env';

interface InstallList {
  installList: AppEnv.IntegrationStats | undefined;
}

const InstallList = ({installList}: InstallList) => {
  const {t} = useTranslation();

  const installed = installList?.reduce(
    (res, item) => res + item.integration_install,
    0
  );

  const uninstalled = installList?.reduce(
    (res, item) => res + item.integration_uninstall,
    0
  );

  const keys = {
    integration_install: {
      color: '#83d11f',
      label: t`Installed`,
      value: installed || 0
    },
    integration_uninstall: {
      color: '#ff8242',
      label: t`Uninstalled`,
      value: uninstalled || 0
    }
  };

  return (
    <div style={{marginBottom: 32}}>
      <Title.H2 style={{marginBottom: 32}}>{t`Installations`}</Title.H2>

      {installList ? (
        installList.length ? (
          <BarGraph data={installList} keys={keys}>
            <Legend data={Object.values(keys)} />
          </BarGraph>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}
    </div>
  );
};

export default InstallList;
