import React from 'react';
import {Box, Wrapper} from 'whatcrm-core';

import * as AppEnv from 'app-env';

import Actions from './actions/actions';
import BasicInfo from './system-info/system-info';
import ConnectionContext from './connection-context';
import Header from './header/header';
import IntegrationInfo from './integration-info/integration-info';
import PaymentInfo from './payment-info/payment-info';
import UserInfo from './user-info/user-info';

interface Connection {
  connection: AppEnv.Instance;
}

const Connection = ({connection}: Connection) => (
  <ConnectionContext.Provider value={{connection}}>
    <Box color={connection.is_deleted ? 'accent' : undefined}>
      <Wrapper isColumn justifyContent="space-between" style={{height: '100%'}}>
        <Wrapper gap={12} isColumn>
          <Header />
          <PaymentInfo />
          <IntegrationInfo />
          <BasicInfo />
          <UserInfo />
        </Wrapper>

        <Actions />
      </Wrapper>
    </Box>
  </ConnectionContext.Provider>
);

export default Connection;
