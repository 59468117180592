import React from 'react';
import {MultiSelect} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const Quantity = () => {
  const [crmField] = useField<string | undefined>('crm');

  const [field, , helpers] = useField<string | string[] | undefined>(
    'quantity'
  );

  const {t} = useTranslation();

  const options = [
    {label: t`30 days`, value: '30'},
    {label: t`3 months`, value: '3'},
    {label: t`6 months`, value: '6'},
    {label: t`12 months`, value: '12'}
  ];

  const value = typeof field.value == 'string' ? [field.value] : field.value;

  return (
    <MultiSelect
      {...field}
      isDisabled={!crmField.value}
      onChange={helpers.setValue}
      options={options}
      placeholder={t`Period`}
      style={{marginBottom: 8}}
      value={value}
    />
  );
};

export default Quantity;
