import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {useConnectionContext} from '../../connection-context';
import {useHomeContext} from 'pages/connections/home/home-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

const useActions = () => {
  const {pushNotification} = useAppContext();
  const {getConnectionList} = useHomeContext();
  const {connection} = useConnectionContext();

  const {deleteInstances, updateInstance} = useRequest();
  const {t} = useTranslation();

  const connectionLabel = `${t`Connection`} ${connection.id}`;

  interface CloseError {
    error_code: number;
    error_text: string;
  }

  interface CloseSuccess {
    _: 'ok';
  }

  const closeConnection = async () => {
    const {data} = await updateInstance<CloseError | CloseSuccess>(
      connection.chat_key,
      connection.version == 'whatcrm' ? 'destroy' : 'close'
    );

    if (data) {
      if ('_' in data) {
        pushNotification(connectionLabel, t`The connection has been closed`);
        return;
      }

      pushNotification(`${t`Error`} ${data.error_code}`, data.error_text);
    }
  };

  const deleteConnection = async (
    res: boolean,
    setIsConfirmationActive: AppEnv.SetState<boolean>
  ) => {
    if (!res) {
      setIsConfirmationActive(false);
      return true;
    }

    const {data} = await deleteInstances(connection.id);
    if (typeof data != 'string') return true;

    pushNotification(connectionLabel, t`The connection has been deleted`);
    setIsConfirmationActive(false);
    getConnectionList();

    return true;
  };

  interface WebVersionSuccess {
    result: string;
  }

  interface WebVersionError {
    error: number | null;
    error_code: number | null;
    error_text: string;
  }

  const getConnectionVersion = async () => {
    const {data} = await updateInstance<WebVersionSuccess | WebVersionError>(
      connection.chat_key,
      'WWebVersion'
    );

    if (data) {
      if ('result' in data) {
        return data.result;
      }

      pushNotification(
        `${t`Error`}${data.error_code ? ` ${data.error_code}` : ''}`,
        data.error_text
      );
    }

    return null;
  };

  interface LogoutSuccess {
    success: 1;
  }

  interface LogoutError {
    error: string;
    error_code: string;
    error_text: string;
  }

  const logoutConnection = async (
    res: boolean,
    setIsConfirmationActive: AppEnv.SetState<boolean>
  ) => {
    if (res) {
      const {data} = await updateInstance<LogoutSuccess | LogoutError>(
        connection.chat_key,
        'logout'
      );

      pushNotification(
        connectionLabel,
        data && 'error_text' in data
          ? data.error_text
          : t`The logout has been completed`
      );
    }

    setIsConfirmationActive(false);
    return true;
  };

  return {
    closeConnection,
    deleteConnection,
    getConnectionVersion,
    logoutConnection
  };
};

export default useActions;
