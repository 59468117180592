import React, {useEffect, useState} from 'react';

import WorkspaceContext from 'workspace/workspace-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import {useGetFusedCrmList, usePages} from './hooks';

interface Workspace {
  children: React.ReactNode;
}

const Workspace = ({children}: Workspace) => {
  const [crmList, setCrmList] = useState<AppEnv.Crm[]>();
  const [tokenList, setTokenList] = useState<AppEnv.Token[]>();
  const [userList, setUserList] = useState<AppEnv.User[]>();

  const {fetchCrmTypes, fetchTokens, fetchUsers} = useRequest();
  const getFusedCrmList = useGetFusedCrmList(crmList || []);
  const pages = usePages();

  const requestCrmList = async (
    crms: AppEnv.Crm[] = [],
    page = 1
  ): Promise<AppEnv.Crm[]> => {
    const {data, headers} = await fetchCrmTypes(page);

    const totalCount = headers?.['x-pagination-total-count'];
    if (!data || !totalCount) return crms;

    const res = [...crms, ...data];
    if (res.length < parseInt(totalCount)) return requestCrmList(res, page + 1);

    return res;
  };

  const getCrmList = async () => {
    const res = await requestCrmList();
    setCrmList(res);
  };

  const requestTokens = async (
    tokens: AppEnv.Token[] = [],
    page = 1
  ): Promise<AppEnv.Token[]> => {
    const {data, headers} = await fetchTokens(page);

    const totalCount = headers?.['x-pagination-total-count'];
    if (!data || !totalCount) return tokens;

    const res = [...tokens, ...data];
    if (res.length < parseInt(totalCount)) return requestTokens(res, page + 1);

    return res;
  };

  const getTokenList = async () => {
    if (tokenList) return;

    const res = await requestTokens();
    setTokenList(res);
  };

  const requestUsers = async (
    users: AppEnv.User[] = [],
    page = 1
  ): Promise<AppEnv.User[]> => {
    const {data, headers} = await fetchUsers(page);

    const totalCount = headers?.['x-pagination-total-count'];
    if (!data || !totalCount) return users;

    const res = [...users, ...data];
    if (res.length < parseInt(totalCount)) return requestUsers(res, page + 1);

    return res;
  };

  const getUserList = async () => {
    if (userList) return;

    const res = await requestUsers();
    setUserList(res || []);
  };

  useEffect(() => {
    getCrmList();
  }, []);

  return (
    <>
      {crmList && (
        <WorkspaceContext.Provider
          value={{
            crmList,
            getFusedCrmList,
            getTokenList,
            getUserList,
            pages,
            setCrmList,
            setTokenList,
            setUserList,
            tokenList,
            userList
          }}
        >
          {children}
        </WorkspaceContext.Provider>
      )}
    </>
  );
};

export default Workspace;
