import React from 'react';
import {MultiSelect, Option} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import * as AppEnv from 'app-env';

interface Plan {
  tariffList: AppEnv.Rate[] | undefined;
}

const Plan = ({tariffList}: Plan) => {
  const [crmField] = useField<string | undefined>('crm');
  const [field, , helpers] = useField<string | string[] | undefined>('plane');
  const {t} = useTranslation();

  const options = tariffList?.reduce((acc, tariff) => {
    if (!acc.some(item => item.value == tariff.plane))
      acc.push({label: tariff.plane, value: tariff.plane});

    return acc;
  }, [] as Option<AppEnv.RatePlan>[]);

  const value = typeof field.value == 'string' ? [field.value] : field.value;

  return (
    <MultiSelect
      {...field}
      isDisabled={!crmField.value}
      onChange={helpers.setValue}
      options={crmField.value ? options : []}
      placeholder={t`Rate plan`}
      style={{marginBottom: 8}}
      value={value}
    />
  );
};

export default Plan;
