import React from 'react';
import {
  EmptyBox,
  Preloader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
  Title
} from 'whatcrm-core';
import {Trans, useTranslation} from 'react-i18next';

import * as AppEnv from 'app-env';

interface OnePayList {
  onePayList: AppEnv.StatisticsOnePay[] | undefined;
}

const OnePayList = ({onePayList}: OnePayList) => {
  const {i18n, t} = useTranslation();

  return (
    <div>
      <Title.H2 style={{marginBottom: 16}}>{t`Conversion`}</Title.H2>

      {onePayList ? (
        onePayList.length ? (
          <Table>
            <TableHead>
              <TableCell />

              {onePayList[0]?.data.map((item, i) => (
                <TableCell key={i}>{item.type}</TableCell>
              ))}
            </TableHead>

            <TableBody>
              {onePayList.map(onePay => (
                <TableRow key={onePay.period}>
                  <TableCell isPrimary>
                    <Text>{onePay.period}</Text>
                  </TableCell>

                  {onePay.data.map((item, i) => (
                    <TableCell key={i}>
                      <Text color="dark" isParagraph style={{marginBottom: 4}}>
                        {(
                          item.payments / item.integrations || 0
                        ).toLocaleString(i18n.resolvedLanguage, {
                          style: 'percent'
                        })}
                      </Text>

                      <Text isParagraph>
                        <Trans count={item.integrations}>
                          {{integration: item.integrations}}
                          {' '}
                          {'installations'}
                        </Trans>
                      </Text>

                      <Text isParagraph>
                        <Trans count={item.payments}>
                          {{payments: item.payments}}
                          {' '}
                          {'payments'}
                        </Trans>
                      </Text>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}
    </div>
  );
};

export default OnePayList;
