import React, {useState, useEffect} from 'react';
import {
  Wrapper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  Checkbox,
  Pagination,
  Preloader,
  EmptyBox,
  usePagination
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {EntriesNumber} from 'components';
import Template from '../template/template';
import {useAppContext} from 'app-context';
import {
  useFilter,
  usePaginationParam,
  useRequest,
  useSearchParams
} from 'common/hooks';
import {Roles} from 'app-enums';
import * as AppEnv from 'app-env';

import Header from './header/header';
import Payment from './payment/payment';
import Filter from './filter/filter';
import filterToRequest from './filter-to-request';
import PaymentListContext from './payment-list-context';

const PaymentList = () => {
  const {client} = useAppContext();
  const [paymentList, setPaymentList] = useState<AppEnv.Payment[]>();

  const [selectedPaymentIdList, setSelectedPaymentIdList] = useState<number[]>(
    []
  );

  const [isLoading, setIsLoading] = useState<boolean>();

  const {t} = useTranslation();
  const {fetchBillings} = useRequest();
  const {page = '1'} = useSearchParams();
  const pagination = usePagination(parseInt(page));

  usePaginationParam(pagination.currentPage);

  const {
    filter,
    isFilterActive,
    setFilter,
    setIsFilterActive
  }: AppEnv.Filter<AppEnv.PaymentFilter> = useFilter();

  useEffect(() => {
    document.title = `${t`Payments`} - Whatcrm Console`;
  }, []);

  const getPaymentList = async () => {
    if (typeof isLoading == 'boolean') {
      setIsLoading(true);
    }

    const {headers, data} = await fetchBillings(
      pagination.currentPage,
      filterToRequest(filter)
    );

    pagination.setPagination(
      headers?.['x-pagination-current-page'],
      headers?.['x-pagination-page-count'],
      headers?.['x-pagination-total-count']
    );

    setPaymentList(data || []);
    setIsLoading(false);
  };

  useEffect(() => {
    getPaymentList();
  }, [filter, pagination.currentPage]);

  const handleChange = () =>
    setSelectedPaymentIdList(prevValue =>
      prevValue.length == paymentList?.length
        ? []
        : paymentList?.map(item => item.id) || []
    );

  const isDeleteShown =
    !!client?.access_level && client.access_level >= Roles.ADMIN;

  const isEventsShown =
    !!client?.access_level && client.access_level >= Roles.MODER;

  const isRefundShown =
    !!client?.access_level && client.access_level >= Roles.SUPMODER;

  return (
    <>
      <PaymentListContext.Provider
        value={{
          selectedPaymentIdList,
          setSelectedPaymentIdList,
          getPaymentList
        }}
      >
        <Template
          filter={
            <Filter
              filter={filter}
              isFilterActive={isFilterActive}
              isDisabled={!paymentList}
              setFilter={setFilter}
              setIsFilterActive={setIsFilterActive}
            />
          }
        >
          <Wrapper gap={16} isColumn>
            <Header
              isFilterActive={isFilterActive}
              setIsFilterActive={setIsFilterActive}
            />

            {paymentList ? (
              paymentList.length ? (
                <div>
                  <EntriesNumber
                    number={pagination.totalCount}
                    style={{marginBottom: 8}}
                  />

                  <Table style={{marginBottom: 8}}>
                    <TableHead>
                      <TableCell>
                        <Checkbox
                          value={
                            selectedPaymentIdList.length == paymentList.length
                          }
                          onChange={handleChange}
                        />
                      </TableCell>

                      <TableCell>{`${t`Date`} (UTC+3)`}</TableCell>
                      <TableCell>{t`Domain`}</TableCell>
                      <TableCell>{'CRM'}</TableCell>
                      <TableCell>{t`Payment system`}</TableCell>
                      <TableCell>{t`Rate`}</TableCell>
                      <TableCell>{'ID'}</TableCell>
                      <TableCell>{t`Quantity`}</TableCell>
                      <TableCell>{t`Amount`}</TableCell>
                      <TableCell>{t`Partner`}</TableCell>
                      <TableCell />
                      <TableCell />
                      {isEventsShown && <TableCell />}
                      {isRefundShown && <TableCell />}
                      {isDeleteShown && <TableCell />}
                    </TableHead>

                    <TableBody>
                      {paymentList.map(item => (
                        <Payment
                          isDeleteShown={isDeleteShown}
                          isEventsShown={isEventsShown}
                          isRefundShown={isRefundShown}
                          key={item.id}
                          payment={item}
                          setPaymentList={setPaymentList}
                        />
                      ))}
                    </TableBody>
                  </Table>

                  <Pagination {...pagination} isSticky />
                </div>
              ) : (
                <EmptyBox />
              )
            ) : (
              <Preloader />
            )}
          </Wrapper>
        </Template>
      </PaymentListContext.Provider>

      {isLoading && <Preloader isFullScreen />}
    </>
  );
};

export default PaymentList;
