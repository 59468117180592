import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {useConnectionContext} from '../../../../connection-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';
import useUpdInstance from '../use-upd-instance/use-upd-instance';

const useHandleFree = (setIsFreeConfirmActive: AppEnv.SetState<boolean>) => {
  const {pushNotification} = useAppContext();
  const {connection} = useConnectionContext();

  const {freeInstance} = useRequest();
  const {t} = useTranslation();
  const updInstance = useUpdInstance();

  const free = async () => {
    const {data} = await freeInstance(connection.chat_key);
    if (!data) return true;

    updInstance(data);
    setIsFreeConfirmActive(false);

    const label = `${t`Connection`} ${connection.id}`;
    pushNotification(label, t`Connection released.`);

    return true;
  };

  const handleFree = (res: boolean) => {
    if (res) return free();
    setIsFreeConfirmActive(false);
  };

  return handleFree;
};

export default useHandleFree;
