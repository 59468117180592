import React, {useEffect, useState} from 'react';
import {Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {useGetRateList} from 'common/hooks';
import * as AppEnv from 'app-env';

import Crm from './crm/crm';
import Currency from './currency/currency';
import Plane from './plane/plane';
import Quantity from './quantity/quantity';

const TariffId = () => {
  const [tariffList, setTariffList] = useState<AppEnv.Rate[]>();

  const [, , helpers] = useField<string | string[] | undefined>('tariff_id');
  const [currencyField] = useField<string | string[] | undefined>('currency');
  const [crmField] = useField<string | undefined>('crm');
  const [planeField] = useField<string | string[] | undefined>('plane');
  const [quantityField] = useField<string | string[] | undefined>('quantity');
  const {t} = useTranslation();
  const getRateList = useGetRateList();

  const getIsCurrency = (tariff: AppEnv.Rate) => {
    if (!currencyField.value?.length) return true;
    else if (typeof currencyField.value == 'string')
      return currencyField.value == tariff.country;

    return currencyField.value.some(currency => currency == tariff.country);
  };

  const getIsPlane = (tariff: AppEnv.Rate) => {
    if (!planeField.value?.length) return true;
    else if (typeof planeField.value == 'string')
      return planeField.value == tariff.plane;

    return planeField.value.some(plane => plane == tariff.plane);
  };

  const getIsQuantity = (tariff: AppEnv.Rate) => {
    if (!quantityField.value?.length) return true;
    else if (typeof quantityField.value == 'string')
      return +quantityField.value == tariff.quantity;

    return quantityField.value.some(quantity => +quantity == tariff.quantity);
  };

  const getTariffId = (tariffs: AppEnv.Rate[] | undefined) => {
    const fusedTariffs = tariffs?.filter(
      tariff =>
        getIsCurrency(tariff) && getIsPlane(tariff) && getIsQuantity(tariff)
    );

    console.log(fusedTariffs);

    const tariffId = fusedTariffs?.map(tariff => tariff.id.toString());
    helpers.setValue(tariffId);
  };

  const getTariffList = async () => {
    setTariffList(undefined);
    if (!crmField.value) return helpers.setValue(undefined);

    const {rateList} = await getRateList(+crmField.value);

    getTariffId(rateList);
    setTariffList(rateList);
  };

  useEffect(() => {
    getTariffList();
  }, [crmField.value]);

  useEffect(() => {
    getTariffId(tariffList);
  }, [currencyField.value, planeField.value, quantityField.value]);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Rate`}</Title.H3>
      <Crm />
      <Plane tariffList={tariffList} />
      <Quantity />
      <Currency />
    </div>
  );
};

export default TariffId;
