import React, {useEffect, useState} from 'react';
import {
  Popup as CorePopup,
  Title,
  Text,
  Line,
  Wrapper,
  Button,
  useCopyToClipboard
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {Code} from 'components';
import {useAppContext} from 'app-context';
import {useConnectionContext} from '../../../connection-context';

interface Popup {
  version?: string;
  isPropsActive?: boolean;
  onClick: () => void;
}

const Popup = ({version, isPropsActive, onClick}: Popup) => {
  const {pushNotification} = useAppContext();
  const {connection} = useConnectionContext();

  const [isActive, setIsActive] = useState(false);

  const copyToClipboard = useCopyToClipboard();
  const {t} = useTranslation();

  useEffect(() => {
    if (version || isPropsActive) {
      setIsActive(true);
    }
  }, [version, isPropsActive]);

  const handleCopy = async () => {
    const res = await copyToClipboard(JSON.stringify(connection));
    pushNotification(res.message);
  };

  const handleClick = () => {
    setIsActive(false);
    setTimeout(() => onClick(), 200);
  };

  return (
    <CorePopup
      width={isPropsActive ? 790 : undefined}
      isActive={isActive}
      onClick={handleClick}
    >
      <Title.H2 style={{marginBottom: 8}}>
        {`${t`Connection`} ${connection.id}`}
      </Title.H2>

      {version && (
        <Text>
          {t`Version`}: {version}
        </Text>
      )}

      {isPropsActive && <Code code={JSON.stringify(connection)} />}
      <Line />

      <Wrapper gap={8}>
        {isPropsActive ? (
          <>
            <Button onClick={handleCopy}>{t`Copy`}</Button>

            <Button color="white" onClick={handleClick}>
              {t`Close`}
            </Button>
          </>
        ) : (
          <Button onClick={handleClick}>{t`OK`}</Button>
        )}
      </Wrapper>
    </CorePopup>
  );
};

export default Popup;
