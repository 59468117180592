import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {useConnectionContext} from '../../../../connection-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';
import useUpdInstance from '../use-upd-instance/use-upd-instance';

const useHandleEnable = (
  setIsEnableConfirmActive: AppEnv.SetState<boolean>
) => {
  const {pushNotification} = useAppContext();
  const {connection} = useConnectionContext();

  const {putInstances} = useRequest();
  const {t} = useTranslation();
  const updInstance = useUpdInstance();

  const enable = async () => {
    const {data} = await putInstances(connection.id, {
      is_deleted: connection.is_deleted ? 0 : 1
    });

    if (!data) return true;

    updInstance(data);
    setIsEnableConfirmActive(false);

    const label = `${t`Connection`} ${connection.id}`;

    pushNotification(
      label,
      data.is_deleted == 0
        ? t`The connection has been enabled`
        : data.is_deleted
          ? t`The connection has been disabled`
          : t`The connection is temporarily unavailable`
    );

    return true;
  };

  const handleEnable = (res: boolean) => {
    if (res) return enable();
    setIsEnableConfirmActive(false);
  };

  return handleEnable;
};

export default useHandleEnable;
