import React, {useEffect, useState} from 'react';
import {
  Button,
  Checkbox,
  Icons,
  Mark,
  TableCell,
  TableRow,
  Text,
  Wrapper
} from 'whatcrm-core';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import update from 'immutability-helper';

import {useAppContext} from 'app-context';
import {usePaymentListContext} from '../payment-list-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Delete from './delete/delete';
import Domain from './domain/domain';
import Editor from './editor/editor';
import Refund from './refund/refund';
import Transaction from './transaction/transaction';

interface Payment {
  isDeleteShown: boolean;
  isEventsShown: boolean;
  isRefundShown: boolean;
  payment: AppEnv.Payment;
  setPaymentList: AppEnv.SetState<AppEnv.Payment[] | undefined>;
}

const Payment = ({
  isDeleteShown,
  isEventsShown,
  isRefundShown,
  payment,
  setPaymentList
}: Payment) => {
  const {pushNotification} = useAppContext();

  const {selectedPaymentIdList, setSelectedPaymentIdList} =
    usePaymentListContext();

  const [isActive, setIsActive] = useState(false);
  const [isEditorActive, setIsEditorActive] = useState(false);
  const [isTransactionActive, setIsTransactionActive] = useState(false);

  const {i18n, t} = useTranslation();
  const {putBillings} = useRequest();

  useEffect(() => {
    setIsActive(isEditorActive);
  }, [isEditorActive]);

  const handleChange = () =>
    setSelectedPaymentIdList(prevValue =>
      update(prevValue, {
        ...(prevValue.includes(payment.id)
          ? {$splice: [[prevValue.indexOf(payment.id), 1]]}
          : {$push: [payment.id]})
      })
    );

  const updatePayment = (data: AppEnv.Payment) =>
    setPaymentList(prevValue => {
      const index = prevValue?.findIndex(item => item.id == data.id);

      if (typeof index == 'number' && index > -1)
        return update(prevValue, {[index]: {$set: data}});
    });

  const handleSubmit = async (values: AppEnv.PaymentForm) => {
    const {data} = await putBillings(payment.id, values);
    if (!data) return true;

    setIsEditorActive(false);
    updatePayment(data);
    pushNotification(t`The payment has been updated`);

    return true;
  };

  const date_yslug = moment.unix(payment.date_yslug).utcOffset(3);

  const subscriptionId = (
    payment.comment?.split(' ')[0].includes('_')
      ? payment.comment
      : payment.transactionId?.split(' ')[0].includes('_')
        ? payment.transactionId
        : payment.comment || payment.transactionId
  )?.split(' ');

  return (
    <TableRow isActive={isActive}>
      <TableCell>
        <Checkbox
          onChange={handleChange}
          value={selectedPaymentIdList.includes(payment.id)}
        />
      </TableCell>

      <TableCell>
        {date_yslug.format('L')}
        <br />
        {date_yslug.format('LT')}
      </TableCell>

      <Domain payment={payment} />
      <TableCell>{payment.crm}</TableCell>

      <TableCell>
        {payment.column == 'cloudpayment'
          ? 'CloudPayments'
          : payment.column == 'stripe'
            ? 'Stripe'
            : payment.column == 'telphin'
              ? t`Telphin`
              : payment.column == 'tinkoff'
                ? t`Tinkoff`
                : payment.column == 'whatcrm'
                  ? 'Whatcrm'
                  : payment.column}
      </TableCell>

      <TableCell>{payment.tariff_title}</TableCell>

      <TableCell>
        <Wrapper gap={8} isColumn>
          {subscriptionId && (
            <div>
              <Text isParagraph size="s" style={{marginBottom: 4}}>
                {t`Subscription ID`}
              </Text>

              <Text color="dark" isParagraph>
                {subscriptionId.map((item, i) => (
                  <React.Fragment key={i}>
                    {item.includes('_') && i == 0 ? (
                      <Link
                        state={{
                          referrer:
                            window.location.pathname + window.location.search
                        }}
                        to={`/billing/${
                          payment.column == 'cloudpayment'
                            ? `${payment.column}s`
                            : payment.column
                        }?filter_subscription_id=${item}`}
                      >
                        <Button color="transparent" isLink>
                          {item}
                        </Button>
                      </Link>
                    ) : (
                      <span>{item}</span>
                    )}
                  </React.Fragment>
                ))}
              </Text>
            </div>
          )}

          {payment.transactionId && (
            <div>
              <Text isParagraph size="s" style={{marginBottom: 4}}>
                {t`Transaction ID`}
              </Text>

              <Text color="dark" isParagraph>
                {payment.transactionId}
              </Text>
            </div>
          )}
        </Wrapper>
      </TableCell>

      <TableCell>{payment.quantity}</TableCell>

      <TableCell>
        {parseFloat(payment.summa.replaceAll(',', '.')).toLocaleString(
          i18n.language,
          {
            currency: payment.currency || 'RUB',
            style: 'currency'
          }
        )}
      </TableCell>

      <TableCell isIcon>
        {payment.partner_id > 1 ? (
          <Link
            state={{
              referrer: window.location.pathname + window.location.search
            }}
            to={`/integrations?filter_id=${payment.partner_id}`}
          >
            <Button color="transparent" tip={t`Open a partner`}>
              <Mark mark={payment.partner_id} />
            </Button>
          </Link>
        ) : (
          <Mark mark={payment.partner_id} />
        )}
      </TableCell>

      <TableCell>
        <Button
          color="transparent"
          isDisabled={!payment.paymentData}
          onClick={() => setIsTransactionActive(true)}
        >
          {t`Transaction`}
        </Button>

        <Transaction
          isTransactionActive={isTransactionActive}
          payment={payment}
          setIsTransactionActive={setIsTransactionActive}
        />
      </TableCell>

      {isEventsShown && (
        <TableCell isIcon>
          <Link
            state={{
              referrer: window.location.pathname + window.location.search
            }}
            style={{display: 'block'}}
            to={`/events?filter_category=potreblen_yslug&filter_category_id=${payment.id}`}
          >
            <Button color="transparent" tip={t`Open events`}>
              <Icons.Calendar />
            </Button>
          </Link>
        </TableCell>
      )}

      {isRefundShown && (
        <Refund
          payment={payment}
          setIsActive={setIsActive}
          updatePayment={updatePayment}
        />
      )}

      <TableCell isIcon>
        <Button
          color="transparent"
          onClick={() => setIsEditorActive(true)}
          tip={t`Update`}
        >
          <Icons.Edit />
        </Button>

        <Editor
          isActive={isEditorActive}
          onClick={() => setIsEditorActive(false)}
          onSubmit={handleSubmit}
          payment={payment}
        />
      </TableCell>

      {isDeleteShown && <Delete payment={payment} setIsActive={setIsActive} />}
    </TableRow>
  );
};

export default Payment;
