import React, {useState} from 'react';
import {SearchSelect} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'workspace/workspace-context';
import {normalizeDomain} from 'common/actions';
import * as AppEnv from 'app-env';

import * as IntegrationSearchSelectEnv from './integration-search-select-env';

interface IntegrationSearchSelect {
  domain?: string;
  integrationTypeId?: string[];
  isMaxWidth?: boolean;
  isPartner?: boolean;
  isValid?: boolean;
  name: string;
  onChange: (arg: IntegrationSearchSelectEnv.Integration) => void;
  value: number | undefined;
}

const IntegrationSearchSelect = ({
  domain,
  integrationTypeId,
  isMaxWidth,
  isPartner,
  isValid,
  name,
  onChange,
  value
}: IntegrationSearchSelect) => {
  const {crmList} = useWorkspaceContext();

  const [integrationList, setIntegrationList] =
    useState<IntegrationSearchSelectEnv.Integration[]>();

  const {fetchIntegrations} = useRequest();
  const {t} = useTranslation();

  const handleChange = (proposal: number) => {
    const integration = integrationList?.find(item => item.id == proposal);
    if (integration) onChange(integration);
  };

  const getPartnerFilter = (): AppEnv.IntegrationRequest => {
    if (isPartner) return {is_partner: {value: '0', type: '>'}};
    else if (isPartner == false)
      return {is_partner: {value: '0'}, partner_id: {value: '0'}};

    return {};
  };

  const handleSearch = async (search: string) => {
    const partnerFilter = getPartnerFilter();

    const integration_type_id = integrationTypeId
      ? {value: integrationTypeId}
      : undefined;

    const filter: AppEnv.IntegrationRequest = {
      ...partnerFilter,
      domain: {type: 'like', value: normalizeDomain(search)},
      integration_type_id
    };

    const params = {fields: 'domain,id,integration_type_id,is_partner'};

    const {data} = await fetchIntegrations<
      IntegrationSearchSelectEnv.Integration[]
    >({filter, params});

    const options = data?.map(item => {
      const crm = crmList.find(c => c.id == item.integration_type_id);

      return {
        comment: crm?.title,
        label: item.domain,
        value: item.id
      };
    });

    setIntegrationList(data || []);
    return options;
  };

  return (
    <SearchSelect
      initialSearch={domain}
      inputMode="email"
      name={name}
      placeholder={t`Search by domain`}
      value={value}
      isMaxWidth={isMaxWidth}
      isValid={isValid}
      onChange={handleChange}
      onSearch={handleSearch}
    />
  );
};

export * as IntegrationSearchSelectEnv from './integration-search-select-env';
export default IntegrationSearchSelect;
