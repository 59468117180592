import React, {useEffect, useState} from 'react';
import {Button, Line, Popup, Text, Title, Wrapper} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {useConnectionContext} from '../../connection-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

interface Success {
  accountStatus: string;
  state: string;
  statusData: [];
}

interface Status {
  setStatus: AppEnv.SetState<string | undefined>;
  status: string | undefined;
}

const Status = ({setStatus, status}: Status) => {
  const {pushNotification} = useAppContext();
  const {connection} = useConnectionContext();

  const [isActive, setIsActive] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const {updateInstance} = useRequest();
  const {t} = useTranslation();

  useEffect(() => {
    if (!status) return;
    setIsActive(true);
  }, [status]);

  const handleClick = () => {
    setIsActive(false);
    setTimeout(() => setStatus(undefined), 200);
  };

  const handleRun = async () => {
    setIsLoad(true);

    const {data} = await updateInstance<Success>(
      connection.chat_key,
      'status',
      1
    );

    if (data?.state) setStatus(data.state);

    setIsLoad(false);
    pushNotification(t`Status`, data?.state || t`Failed to run connection.`);
  };

  return (
    <Popup isActive={isActive} onClick={handleClick}>
      <Title.H2 style={{marginBottom: 8}}>
        {t`Connection`} {connection.id}
      </Title.H2>

      <Text>
        {t`Status`}
        {': '}
        {status}
      </Text>

      <Line />

      <Wrapper gap={8}>
        <Button isDisabled={isLoad} onClick={handleRun}>{t`Run`}</Button>
        <Button color="white" onClick={handleClick}>{t`Close`}</Button>
      </Wrapper>
    </Popup>
  );
};

export default Status;
