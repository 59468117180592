import React from 'react';

import {Roles} from 'app-enums';
import {Sidebar} from 'components';
import {useAppContext} from 'app-context';
import {useHomeContext} from '../home-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import ChatId from './chat-id/chat-id';
import ChatKey from './chat-key/chat-key';
import DatePay from './date-pay/date-pay';
import DateUpdateAfter from './date-update-after/date-update-after';
import DateUpdateBefore from './date-update-before/date-update-before';
import Domain from './domain/domain';
import Hostname from './hostname/hostname';
import Id from './id/id';
import IsDeleted from './is_deleted/is_deleted';
import Name from './name/name';
import Phone from './phone/phone';
import Platform from './platform/platform';
import Status from './status/status';
import Version from './version/version';

interface Filter {
  filter: AppEnv.InstanceFilter;
  setFilter: AppEnv.SetState<AppEnv.InstanceFilter>;
}

const Filter = ({filter, setFilter}: Filter) => {
  const {client} = useAppContext();
  const {connectionList, isFilterActive, setIsFilterActive} = useHomeContext();

  const {fetchIntegrations} = useRequest();

  const handleSubmit = async (values: AppEnv.InstanceFilter) => {
    let crm_id: string[] | undefined;

    if (values.domain) {
      const {data} = await fetchIntegrations<AppEnv.Integration[]>({
        filter: {domain: {value: values.domain, type: 'like'}}
      });

      crm_id = data?.map(item => item.id.toString()) || ['-1'];
    }

    setFilter({...values, crm_id});
  };

  const initialValues: AppEnv.InstanceFilter = {
    is_deleted: filter.is_deleted || '-1',
    version: filter.version || '-1'
  };

  const isAdmin = client?.access_level && client.access_level == Roles.ADMIN;

  return (
    <Sidebar
      filter={filter}
      initialValues={initialValues}
      isActive={isFilterActive}
      isDisabled={!connectionList}
      onCancel={() => setFilter({})}
      onClick={() => setIsFilterActive(false)}
      onSubmit={handleSubmit}
    >
      <Domain />

      {isAdmin && (
        <>
          <DateUpdateAfter />
          <DateUpdateBefore />
        </>
      )}

      {isAdmin && <Status />}
      <Id />

      {isAdmin && (
        <>
          <ChatId />
          <ChatKey />
          <Hostname />
        </>
      )}

      <Name />
      <Phone />
      <Platform />

      {isAdmin && (
        <>
          <DatePay />
          <Version />
        </>
      )}

      <IsDeleted />
    </Sidebar>
  );
};

export default Filter;
