import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {Button, Icons, Preloader} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useRequest} from 'common/hooks';
import {useStatsContext} from 'pages/stats/stats-context';
import * as AppEnv from 'app-env';

import InstallList from './install-list/install-list';
import OnePayList from './one-pay-list/one-pay-list';

const Integrations = () => {
  const {
    filter,
    isFilterAvailableAndActive,
    setIsFilterActive,
    setIsFilterDisabled
  } = useStatsContext();

  const [installList, setInstallList] = useState<AppEnv.IntegrationStats>();

  const [isLoad, setIsLoad] = useState<boolean>();
  const [onePayList, setOnePayList] = useState<AppEnv.StatisticsOnePay[]>();

  const {fetchStatistics} = useRequest();
  const {t} = useTranslation();

  const getInstallList = async () => {
    if (typeof isLoad == 'boolean') setIsLoad(true);
    const params = _.pick(filter, ['end', 'integration_type_id', 'start']);

    const {data} = await fetchStatistics<AppEnv.IntegrationStats>(
      'integrations',
      {...params}
    );

    setInstallList(data || []);
    setIsLoad(false);
  };

  useEffect(() => {
    getInstallList();
    document.title = `${t`Integration statistics`} - Whatcrm Console`;
  }, [filter]);

  useEffect(() => {
    setIsFilterDisabled(!(installList && onePayList));
  }, [installList, onePayList]);

  const getOnePayList = async () => {
    const {data} = await fetchStatistics<AppEnv.StatisticsOnePay[]>('one_pay');
    setOnePayList(data || []);
  };

  useEffect(() => {
    getOnePayList();
  }, []);

  return (
    <>
      <Button
        color={isFilterAvailableAndActive ? 'active' : 'white'}
        onClick={() => setIsFilterActive(prevValue => !prevValue)}
        style={{marginBottom: 32}}
      >
        <Icons.Filter />
        {t`Filter`}
      </Button>

      <InstallList installList={installList} />
      <OnePayList onePayList={onePayList} />

      {isLoad && <Preloader isFullScreen />}
    </>
  );
};

export default Integrations;
