import React, {useState} from 'react';
import {Confirmation, ContextMenu} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {Roles} from 'app-enums';
import {useAppContext} from 'app-context';
import {useConnectionContext} from '../../connection-context';

import {useHandleEnable, useHandleFree, useHandleResuscitate} from './hooks';
import Api from './api/api';
import Editor from './editor/editor';
import Popup from './popup/popup';
import useActions from './actions';

const ActionMenu = () => {
  const {client} = useAppContext();
  const {connection} = useConnectionContext();

  const [isApiActive, setIsApiActive] = useState(false);

  const [isDeleteConfirmationActive, setIsDeleteConfirmationActive] =
    useState(false);

  const [isEditorActive, setIsEditorActive] = useState(false);
  const [isEnableConfirmActive, setIsEnableConfirmActive] = useState(false);
  const [isFreeConfirmActive, setIsFreeConfirmActive] = useState(false);

  const [isLogoutConfirmationActive, setIsLogoutConfirmationActive] =
    useState(false);

  const [isPropsActive, setIsPropsActive] = useState(false);

  const [isResuscitateConfirmActive, setIsResuscitateConfirmActive] =
    useState(false);

  const [version, setVersion] = useState<string>();

  const {
    closeConnection,
    deleteConnection,
    getConnectionVersion,
    logoutConnection
  } = useActions();

  const {t} = useTranslation();
  const handleEnable = useHandleEnable(setIsEnableConfirmActive);
  const handleFree = useHandleFree(setIsFreeConfirmActive);
  const handleResuscitate = useHandleResuscitate(setIsResuscitateConfirmActive);

  const handleViewVersion = async () => {
    const res = await getConnectionVersion();

    if (res) {
      setVersion(res);
    }
  };

  const handleClick = () => {
    if (version) {
      setVersion(undefined);
    } else if (isPropsActive) {
      setIsPropsActive(false);
    }
  };

  const handleEnableClick = () => {
    if (connection.is_deleted == 0) return setIsEnableConfirmActive(true);
    handleEnable(true);
  };

  const options: {
    value: React.ReactNode;
    isDisabled?: boolean;
    onClick: (() => void) | string;
  }[] = [
    {onClick: closeConnection, value: t`Close`},
    {
      isDisabled: connection.is_deleted != 1,
      onClick: () => setIsDeleteConfirmationActive(true),
      value: t`Delete`
    },
    {
      isDisabled: connection.is_deleted == 2,
      onClick: handleEnableClick,
      value: connection.is_deleted == 0 ? t`Disable` : t`Enable`
    },
    {
      isDisabled: client?.access_level && client.access_level < Roles.ADMIN,
      onClick: () => setIsApiActive(true),
      value: t`Execute method`
    },
    {
      isDisabled: connection.status != 'READY',
      onClick: () => setIsLogoutConfirmationActive(true),
      value: t`Logout`
    },
    {
      isDisabled: !connection.integration,
      onClick: () => setIsFreeConfirmActive(true),
      value: t`Release`
    },
    {onClick: () => setIsResuscitateConfirmActive(true), value: t`Resuscitate`},
    {onClick: () => setIsEditorActive(true), value: t`Change`},
    {onClick: () => setIsPropsActive(true), value: t`View properties`},
    {
      isDisabled: connection.version != 'whatcrm',
      onClick: handleViewVersion,
      value: t`View version`
    }
  ].filter(item => !item.isDisabled);

  return (
    <>
      <ContextMenu options={options} />
      <Api isApiActive={isApiActive} setIsApiActive={setIsApiActive} />

      <Popup
        isPropsActive={isPropsActive}
        onClick={handleClick}
        version={version}
      />

      <Editor
        isEditorActive={isEditorActive}
        setIsEditorActive={setIsEditorActive}
      />

      <Confirmation
        isActive={isResuscitateConfirmActive}
        onClick={handleResuscitate}
        title={t`Resuscitate this connection?`}
      />

      <Confirmation
        isActive={isFreeConfirmActive}
        onClick={handleFree}
        title={t`Release this connection?`}
      />

      <Confirmation
        isActive={isEnableConfirmActive}
        onClick={handleEnable}
        title={t`Disable this connection?`}
      />

      <Confirmation
        isActive={isDeleteConfirmationActive}
        onClick={res => deleteConnection(res, setIsDeleteConfirmationActive)}
        title={t`Are you sure you want to delete this connection?`}
      />

      <Confirmation
        isActive={isLogoutConfirmationActive}
        onClick={res => logoutConnection(res, setIsLogoutConfirmationActive)}
        title={t`Are you sure you want to logout this connection?`}
      />
    </>
  );
};

export default ActionMenu;
