import upd from 'immutability-helper';

import {useHomeContext} from 'pages/connections/home/home-context';
import * as AppEnv from 'app-env';

const useUpdInstance = () => {
  const {setConnectionList} = useHomeContext();

  const updInstance = (instance: AppEnv.Instance) =>
    setConnectionList(prevValue => {
      const index = prevValue?.findIndex(item => item.id == instance.id);
      if (index == undefined || index < 0) return prevValue;

      return upd(prevValue, {[index]: {$set: instance}});
    });

  return updInstance;
};

export default useUpdInstance;
