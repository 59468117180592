import React from 'react';
import {SingleSelect} from 'whatcrm-core';
import {useField} from 'formik';

import {useWorkspaceContext} from 'workspace/workspace-context';

const Crm = () => {
  const {getFusedCrmList} = useWorkspaceContext();
  const [field, , helpers] = useField<string | undefined>('crm');

  const fusedCrmList = getFusedCrmList(1);

  const options = fusedCrmList.map(crm => ({
    comment: crm.description,
    label: crm.title,
    value: crm.id.toString()
  }));

  return (
    <SingleSelect
      {...field}
      isClearable
      onChange={helpers.setValue}
      options={options}
      placeholder="CRM"
      style={{marginBottom: 8}}
    />
  );
};

export default Crm;
